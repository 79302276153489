<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <PageHeader title="Pagina's" subtitle="Beheer pagina's" route="/pages/new" />

    <div class="bg-white shadow overflow-hidden sm:rounded-md mb-5">
      <div class="sm:grid sm:grid-cols-4 sm:items-start sm:py-5">
        <label for="search" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 px-6">
          Search
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-2">
          <div class="max-w-lg flex rounded-md shadow-sm">
            <input type="text" name="search" id="search" autocomplete="search" class="flex-1 block w-full focus:ring-orange-500 focus:border-orange-500 min-w-0 rounded sm:text-sm border-gray-300" v-model="searchQuery" v-on:keyup.enter="search">
          </div>
        </div>
        <div class="block text-sm font-medium text-gray-700">
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="search">search</button>
          <button class="px-4 py-2 md:ml-2 border border-gray-300 text-sm font-medium rounded-md" @click="clear">clear</button>
        </div>
      </div>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md" v-if="content.length > 0">
      
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Published?</span>
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Expertise?
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="c in content" :key="c.id">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{c.title}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium" :class="c.published ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'">
                        {{c.published ? 'published': 'unpublished'}}
                      </span>
                        
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{c.is_proposition}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <router-link :to="'/pages/'+c.id" class="text-orange-600 hover:text-orange-900">edit</router-link>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

    <Pagination v-if="content.length > 0" :page="page" :pages="pages" :itemCount="itemCount" :pageSize="pageSize" @next="next" @previous="previous" />

  </main>
</template>

<script>
import PageService from '../../../services/admin/pages/service';
import Pagination from '../../../components/Pagination';

export default {
  name: 'Pages',
  components: {
    Pagination
  },
  data() {
    return {
      content: [],
      searchQuery: null,
      page: 1,
      pages: 0,
      itemCount: 0,
      pageSize: 8
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      PageService.getPages(this.page, this.pageSize, this.searchQuery).then(
        response => {
          this.content = response.data.items;
          this.pages = response.data.pageCount;
          this.itemCount = response.data.itemCount
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    },
    reset() {
      this.pages = 0;
      this.itemCount = 0;
      this.page = 1;
    },
    clear() {
      this.searchQuery = null;
      this.reset();
      this.getData();
    },
    search() {
      this.reset();
      this.getData();
    },
    next() {
      this.page = this.page + 1;
      this.getData();
    },
    previous() {
      this.page = this.page - 1;
      this.getData();
    }
  }
};
</script>
